import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { getUserBoard } from "../services/user.service";
import { RouteComponentProps } from "react-router-dom";
// import  "./leflet.css";

interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

const BoardUser: React.FC<Props> = ({ history }) => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    const L = require("leaflet");

    // delete L.Icon.Default.prototype._getIconUrl;

    // L.Icon.Default.mergeOptions({
    //   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    //   iconUrl: require("leaflet/dist/images/marker-icon.png"),
    //   shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    // });
    getUserBoard().then(
      (response: any) => {
        setContent(response.data.username);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          if(_content === "Unauthorized") {
            localStorage.removeItem("user");
            history.push("/login");
            window.location.reload();
          }

        setContent(_content);
      }
    );
  }, [history]);

  return (
    <div className="for-height">
        <MapContainer center={[47.505, 8.09]} zoom={6} scrollWheelZoom={true}  zoomControl={false} attributionControl={false}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Marker position={[51.505, -0.09]}>
                <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
            </Marker> */}
        </MapContainer>
    </div>
  );
};

export default BoardUser;