import axios from "axios";

let API_URL = "";

if(process.env.NODE_ENV === "development") {
    API_URL = "http://localhost:9000/api/auth/"
}

if(process.env.NODE_ENV === "production") {
    API_URL = "https://targeting-api.reachad.de/api/api/auth/"
}

console.log('process.env.NODE_ENV :>> ', API_URL);

export const register = (username: string, email: string, password: string) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

export const login = (username: string, password: string) => {
  return axios
    .post(API_URL + "login", {
      username,
      password,
    })
    .then((response: any) => {
        if (response.data.access_token) {
            localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};
