import axios from "axios";
import authHeader from "./auth-header";

let API_URL = "";

if(process.env.NODE_ENV === "development") {
    API_URL = "http://localhost:9000/api/"
}

if(process.env.NODE_ENV === "production") {
    API_URL = "https://targeting-api.reachad.de/api/api/"
}

export const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

export const getUserBoard = () => {
    //@ts-ignore
  return axios.get(API_URL + "profile", { headers: authHeader() });
};

export const getModeratorBoard = () => {
    //@ts-ignore
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

export const getAdminBoard = () => {
    //@ts-ignore
  return axios.get(API_URL + "admin", { headers: authHeader() });
};